import { SelectOptionModel } from "traveldesk-ui";

export default interface ExternalProviderOption extends SelectOptionModel {
    isDifferentProducts: boolean
    codeType: ExternalProviderCodeType
    bookingReferencePrefix: string
}
export enum ExternalProviderCodeType {
    None, QrCode, BarCode
}
export const ExternalProviderCodeTypesNames = ["None", "QR Code", "Bar Code"]