import { toStringFormatWithNumDigits, Localization } from 'traveldesk-ui'
import TimeSlotGroup from './TimeSlotGroup'
import DaysOfferedService from './../services/daysOfferedService'
import { guid } from 'traveldesk-ui'
import Offer from './Offer'
import { ExternalProviderCodeType } from '../pages/TimeSlots/ExternalProviderOption'

export default class TimeSlot {
    public static Create(obj: TimeSlot, tsg: TimeSlotGroup) {
        return Object.assign(new TimeSlot(), obj, {
            groupId: tsg.id,
            groupName: tsg.name,
            timeType: TimeSlot.GetTimeType(obj),
            daysOfferedList: DaysOfferedService.getDays(obj.daysOffered),
            isExternalId: obj.externalProductId && obj.externalProductId.length > 0
        })
    }

    id: number = 0
    guid: string = guid()
    groupId: number = 0
    groupName: string = ''
    name: string = ''
    startTimeHour: number = 0
    startTimeMinutes: number = 0
    endTimeHour: number = 0
    endTimeMinutes: number = 0
    isAnytime: boolean = false
    isNoTime: boolean = false
    interval?: number
    pickUpTimeId?: number
    duration?: number
    cutOffResponseTime?: number
    timeType: TimeSlotTimeType = TimeSlotTimeType.Fixed
    externalId: string = ''
    externalPerChildId: string = ''
    isExternalId: boolean = false
    bookingReferencePrefix: string = ''
    externalProviderId?: number
    externalProviderCodeType: ExternalProviderCodeType = ExternalProviderCodeType.None
    externalProviderDiffProductsAdultChild: boolean = false
    externalProductId: string = ""
    externalProductPerChildId: string = ""
    externalIsTicketPerPax: boolean = false
    offers: Offer[] = []
    public static GetTimeType(obj: TimeSlot) {
        if (obj.isNoTime) return TimeSlotTimeType.NoTime
        if (obj.isAnytime) return TimeSlotTimeType.Anytime
        return TimeSlotTimeType.Fixed
    }
    // public static GetTimeType(obj: TimeSlot) {
    //     if (obj.isNoTime) return TimeSlotTimeType.NoTime
    //     if (obj.isAnytime) return TimeSlotTimeType.Anytime
    //     return TimeSlotTimeType.Fixed
    // }
    public static SetTimeType(obj: TimeSlot, value: TimeSlotTimeType) {
        obj.isNoTime = false
        obj.isAnytime = false
        switch (value) {
            case TimeSlotTimeType.Anytime:
                obj.isAnytime = true
                break
            case TimeSlotTimeType.NoTime:
                obj.isNoTime = true
                break
            default:
                break
        }
    }
    get startTimeDescription() {
        return `${toStringFormatWithNumDigits(this.startTimeHour)}:${toStringFormatWithNumDigits(
            this.startTimeMinutes,
        )}`
    }
    set startTimeDescription(value: string) {
        try {
            const parts = value.split(':')
            const hours = parseInt(parts[0])
            const minutes = parseInt(parts[1])
            this.startTimeHour = hours
            this.startTimeMinutes = minutes
        } catch {
            this.startTimeHour = 0
            this.startTimeMinutes = 0
        }
    }
    get startTimeTotalMinutes() {
        return this.startTimeHour * 60 + this.startTimeMinutes
    }
    get endTimeTotalMinutes() {
        return this.endTimeHour * 60 + this.endTimeMinutes
    }
    set endTimeDescription(value: string) {
        try {
            const parts = value.split(':')
            const hours = parseInt(parts[0])
            const minutes = parseInt(parts[1])
            this.endTimeHour = hours
            this.endTimeMinutes = minutes
        } catch {
            this.endTimeHour = 0
            this.endTimeMinutes = 0
        }
    }
    get endTimeDescription() {
        return `${toStringFormatWithNumDigits(this.endTimeHour)}:${toStringFormatWithNumDigits(this.endTimeMinutes)}`
    }
    get timeDescription() {
        return `${this.startTimeDescription} - ${this.endTimeDescription}`
    }
    // group: TimeSlotGroup = new TimeSlotGroup()
    // get groupId() {
    //     return this.group.id
    // }
    daysOffered: number = 0
    daysOfferedList: number[] = []
    // get daysOfferedList() {
    //     console.log('Get daus ooffere')
    //     const res = DaysOfferedService.getDays(this.daysOffered)
    //     return res
    // }
    // set daysOfferedList(value: number[]) {
    //     console.log('Set days of')
    //     this.daysOffered = DaysOfferedService.encode(value)
    // }
    capacity: number = 0
    minimumAdvancedBooking: number = 0
    type: TimeSlotType = TimeSlotType.Live
}
export enum TimeSlotType {
    Live,
    OnRequest,
}
export enum TimeSlotTimeType {
    Fixed,
    Anytime,
    NoTime,
}
export const getTimeSlotTypeOptions = (localization: Localization.Localization) => [
    { label: localization.getString('Live availability'), value: TimeSlotType.Live },
    { label: localization.getString('Manual confirmation'), value: TimeSlotType.OnRequest },
]
export const getTimeSlotTimeTypeOptions = (localization: Localization.Localization) => [
    { label: localization.getString('A specific time'), value: TimeSlotTimeType.Fixed },
    { label: localization.getString('Time is not applicable'), value: TimeSlotTimeType.NoTime },
    { label: localization.getString('Guest defines time'), value: TimeSlotTimeType.Anytime },
]

// export interface TimeSlotOption {
//     value: string
//     id: number
//     label: string
//     startTime: number
//     endTime: number
// }
