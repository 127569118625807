import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import parseId from '../../services/parseId'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as timeSlotsActions } from '../../store/TimeSlots'
import { ApplicationState } from './../../store/index'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
    Grid,
    Row,
    Col,
    ContainerWithPadding,
    SectionTitle,
    TextFormField,
    NumberFormField,
    TimePickerFormFieldController,
    FormFieldHidable,
    Container,
    FieldFooter,
    IdFormField,
    SelectOptionModel,
    Tooltip,
    SelectFormFieldController,
    Select,
    ToggleFieldController,
    ToggleFormField,
    LabelFormField,
} from 'traveldesk-ui'
import TimeSlotGroup from './../../models/TimeSlotGroup'
import { nameof } from 'traveldesk-ui'

import TimeSlot, {
    getTimeSlotTimeTypeOptions,
    TimeSlotType,
    TimeSlotTimeType,
    getTimeSlotTypeOptions,
} from '../../models/TimeSlot'
import DaysOfferedService from './../../services/daysOfferedService'
import { Buttons } from 'traveldesk-ui'
import { actionCreators as pickUpTimesActions } from './../../store/PickUpTimes'
import { LoadingSection } from '../../components/LoadingSection'
import ExternalProviderOption, { ExternalProviderCodeType, ExternalProviderCodeTypesNames } from './ExternalProviderOption'

interface Props extends RouteComponentProps<{ id: string }> { }

const TimeSlotsForm = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const pickUpTimesOptions = useSelector((state: ApplicationState) => state.pickUpTimes.options)
    const timeSlotGroup = useSelector((state: ApplicationState) => state.timeSlots.timeSlotGroup)
    const externalProvidersOptions = useSelector((state: ApplicationState) => state.timeSlots.externalProvidersOptions)
    const externalOffersMap = useSelector((state: ApplicationState) => state.timeSlots.externalOffersOptions)
    const externalTimeSlotsMap = useSelector((state: ApplicationState) => state.timeSlots.externalTimeSlotsOptions)
    const timeSlotsOptions = React.useMemo(() => {
        const res = DaysOfferedService.getDaysNamesOptions(Localization)
        return res
    }, [Localization])
    React.useEffect(() => {
        if (pickUpTimesOptions.length == 0) {
            dispatch(pickUpTimesActions.requestPickUpTimesOptions())
        }
        if (!externalProvidersOptions) {
            dispatch(timeSlotsActions.requestExternalProviders())
        }
    }, [])

    React.useEffect(() => {
        const id = parseId(props.match.params.id)
        if (typeof id == undefined) {
            props.history.push('/timeslots')
        }
        dispatch(timeSlotsActions.manageTimeSlotGroup(id || 0))
    }, [props.match.params.id])
    React.useEffect(() => {
        if (timeSlotGroup) {
            const externalSlots = timeSlotGroup.timeSlots.filter(x => x.externalProductId && typeof x.externalProviderId != 'undefined')
            if (externalSlots?.length > 0) {
                const externalProviders = externalSlots.reduce((res: number[], ts: TimeSlot) => {
                    if (typeof ts.externalProviderId != 'undefined' && res.indexOf(ts.externalProviderId) < 0) {
                        res.push(ts.externalProviderId)
                    }
                    return res
                }, [] as number[])
                externalProviders.forEach(value => dispatch(timeSlotsActions.requestExternalOffers(value)))
                const externalOffers = externalSlots
                    .filter(x => x.externalId && x.externalId.length > 0)
                    .reduce((res: { apiProviderId: number, productId: string }[], ts: TimeSlot) => {
                        if (ts.externalProductId && !res.some(x => x.productId == ts.externalProductId && x.apiProviderId == ts.externalProviderId)) {
                            res.push({ apiProviderId: ts.externalProviderId || 0, productId: ts.externalProductId })
                        }
                        return res
                    }, [] as { apiProviderId: number, productId: string }[])
                externalOffers.forEach(value => dispatch(timeSlotsActions.requestExternalTimeSlots(value.apiProviderId, value.productId)))
            }
        }
        reset(timeSlotGroup)
    }, [timeSlotGroup, externalProvidersOptions])
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
        watch,
        reset,
        setError,
    } = useForm()
    const onSubmit = (data: any) => {
        if (data.timeSlots && data.timeSlots.length > 0) {
            data.timeSlots = data.timeSlots.map((ts: any) => {
                const _ts = Object.assign(new TimeSlot(), ts, {
                    daysOffered: DaysOfferedService.encode(ts.daysOfferedList),
                })
                _ts.startTimeDescription = ts.startTimeDescription
                _ts.endTimeDescription = ts.endTimeDescription
                TimeSlot.SetTimeType(_ts, _ts.timeType)
                return _ts
            })
            dispatch(timeSlotsActions.save(data))
        }
    }
    const getTimeSlotFieldsNames = (index: number) => {
        return {
            id: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('id')}`,
            name: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('name')}`,
            daysOffered: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('daysOfferedList')}`,
            startTime: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('startTimeDescription')}`,
            endTime: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('endTimeDescription')}`,
            capacity: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('capacity')}`,
            availabilityType: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('type')}`,
            cutOffResponseTime: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>(
                'cutOffResponseTime',
            )}`,
            timeType: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('timeType')}`,
            interval: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('interval')}`,
            duration: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('duration')}`,
            pickUpTimeId: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('pickUpTimeId')}`,
            externalId: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('externalId')}`,
            externalPerChildId: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('externalPerChildId')}`,
            bookingReferencePrefix: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('bookingReferencePrefix')}`,
            externalProviderDiffProductsAdultChild: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('externalProviderDiffProductsAdultChild')}`,
            isExternalId: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('isExternalId')}`,
            externalIsTicketPerPax: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('externalIsTicketPerPax')}`,
            externalProviderId: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('externalProviderId')}`,
            externalProviderCodeType: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('externalProviderCodeType')}`,
            externalProductId: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('externalProductId')}`,
            externalProductPerChildId: `${nameof<TimeSlotGroup>('timeSlots')}[${index}].${nameof<TimeSlot>('externalProductPerChildId')}`,

        }
    }
    const timeSlots = watch('timeSlots') as TimeSlot[]
    const isTimeSlotsIndependant = watch("isTimeSlotsIndependant")
    const onAddTimeSlot = () => {
        if (timeSlotGroup) {
            const data = TimeSlotGroup.Create(getValues() as any)
            data.timeSlots = (data.timeSlots || []).concat(TimeSlot.Create(new TimeSlot(), timeSlotGroup))
            reset(data)
        }
    }
    const timeSlotTypesOptioons = React.useMemo(() => getTimeSlotTypeOptions(Localization), [Localization])
    const timeSlotTimeTypesOptions = React.useMemo(() => getTimeSlotTimeTypeOptions(Localization), [Localization])
    return (
        <ContainerWithPadding>
            {timeSlotGroup ? (
                <Grid>
                    <SectionTitle
                        title={Localization.getString(
                            timeSlotGroup.id
                                ? `${Localization.getString('Edit time slots')}`
                                : Localization.getString('Add new time slots group'),
                        )}
                    />
                    <Link className="a-back-to-list" to="/timeslots">
                        {Localization.getString('Back to time slots')}
                    </Link>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col type="100">
                                <IdFormField control={control} />
                                <TextFormField
                                    label={Localization.getString('Group Name')}
                                    isRequired
                                    error={errors.name ? errors.name.message : ''}
                                    defaultValue={timeSlotGroup.name}
                                    name={nameof<TimeSlotGroup>('name')}
                                    register={register}
                                    options={{ required: Localization.getString('Group name is required') }}
                                />
                                <NumberFormField
                                    name={nameof<TimeSlotGroup>('capacity')}
                                    isRequired
                                    defaultValue={timeSlotGroup.capacity}
                                    label={Localization.getString('Capacity')}
                                    min={1}
                                    register={register}
                                    max={999999}
                                />
                                <NumberFormField
                                    name={nameof<TimeSlotGroup>('minimumAdvancedBooking')}
                                    defaultValue={timeSlotGroup.minimumAdvancedBooking}
                                    label={`${Localization.getString(
                                        'Minimum advanced booking',
                                    )}, ${Localization.getString('hours')}`}
                                    min={-23}
                                    register={register}
                                    max={2400}
                                />

                                <SelectFormFieldController
                                    name={nameof<TimeSlotGroup>('pickUpTimeId')}
                                    label={Localization.getString('Pick up time')}
                                    options={pickUpTimesOptions}
                                    control={control}
                                />
                                <ToggleFieldController
                                    control={control}
                                    label={Localization.getString("Independant Time Slots")}
                                    name={nameof<TimeSlotGroup>("isTimeSlotsIndependant")}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Container style={{ padding: '50px' }}>
                            <h3>{Localization.getString('Time Slots')}:</h3>
                            <Tooltip text={Localization.getString('Add time slot')}>
                                <Buttons.ButtonSmallGreen onClick={onAddTimeSlot}>
                                    <i className="fa fa-plus" />
                                </Buttons.ButtonSmallGreen>
                            </Tooltip>
                            {timeSlots && timeSlots.length > 0 ? (
                                timeSlots.map((ts, index) => {
                                    const fNames = getTimeSlotFieldsNames(index)
                                    const externalTimeSlotsOptions =
                                        fNames.externalProductId ? (externalTimeSlotsMap?.get(getValues(fNames.externalProviderId))?.get(getValues(fNames.externalProductId)) || []) : []
                                    const externalTimeSlotsOptionsForChild =
                                        fNames.externalProductPerChildId ? (externalTimeSlotsMap?.get(getValues(fNames.externalProviderId))?.get(getValues(fNames.externalProductPerChildId)) || []) : []
                                    const selectedProviderId = getValues(fNames.externalProviderId)
                                    const selectedProdiverOption = (selectedProviderId ? externalProvidersOptions?.find(x => x.value == selectedProviderId) : undefined) as (ExternalProviderOption | undefined)
                                    return [
                                        <Row key={`${ts.guid}.${index}`} id={`ts-${ts.guid}`}>
                                            <Col type="50">
                                                <Controller
                                                    name={fNames.id}
                                                    defaultValue={timeSlots[index].id}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            hidden
                                                            value={timeSlots[index].id}
                                                            name="id"
                                                            type="number"
                                                            onChange={([e]: any) => parseInt(e.target.value, 10)}
                                                        />
                                                    )}
                                                />
                                                <TextFormField
                                                    label={Localization.getString('Time Slot Name')}
                                                    isRequired
                                                    error={
                                                        errors.timeSlots && errors.timeSlots.length > 0 && !ts.name
                                                            ? Localization.getString('Time slot name is required')
                                                            : undefined
                                                    }
                                                    defaultValue={timeSlots[index].name}
                                                    name={fNames.name}
                                                    register={register}
                                                    options={{
                                                        required: Localization.getString('Time slot name is required'),
                                                    }}
                                                />
                                                <SelectFormFieldController
                                                    name={fNames.daysOffered}
                                                    isMultiple
                                                    options={timeSlotsOptions}
                                                    label={Localization.getString('Days offered')}
                                                    error={
                                                        errors[fNames.daysOffered] && errors[fNames.daysOffered].message
                                                    }
                                                    value={fNames.daysOffered}
                                                    onChange={(e: any) => {
                                                        const prev = getValues(fNames.daysOffered) as number[]
                                                        let res = e ? (e as number[]) : []
                                                        if (res.some((x) => x == 0)) {
                                                            if (prev.some((x) => x == 0)) {
                                                                res = res.filter((x) => x != 0)
                                                            } else {
                                                                res = [0]
                                                            }
                                                        }
                                                        setValue(fNames.daysOffered, res)
                                                        return res
                                                    }}
                                                    control={control}
                                                />
                                                {isTimeSlotsIndependant
                                                    ? <NumberFormField
                                                        name={fNames.capacity}
                                                        defaultValue={ts.capacity}
                                                        label={`${Localization.getString(
                                                            'Capacity',
                                                        )}, ${Localization.getString('people')}`}
                                                        min={0}
                                                        register={register}
                                                        onChange={([e]) => parseFloat(e.target.value) || 0}
                                                    />
                                                    : null}
                                                <SelectFormFieldController
                                                    name={fNames.availabilityType}
                                                    label={Localization.getString('Availability settings')}
                                                    options={timeSlotTypesOptioons}
                                                    control={control}
                                                />
                                                <FormFieldHidable hidden={ts.type != TimeSlotType.OnRequest}>
                                                    <NumberFormField
                                                        name={fNames.cutOffResponseTime}
                                                        defaultValue={ts.cutOffResponseTime}
                                                        label={`${Localization.getString(
                                                            'Cut off response time',
                                                        )}, ${Localization.getString('hours')}`}
                                                        min={0}
                                                        register={register}
                                                        onChange={([e]) => parseFloat(e.target.value) || 0}
                                                    />
                                                </FormFieldHidable>
                                                <SelectFormFieldController
                                                    name={fNames.pickUpTimeId}
                                                    label={Localization.getString('Override pick up time')}
                                                    options={pickUpTimesOptions}
                                                    defaultValue={timeSlots[index].pickUpTimeId}
                                                    control={control}
                                                />
                                            </Col>
                                            <Col type="50">
                                                <SelectFormFieldController
                                                    name={fNames.timeType}
                                                    label={Localization.getString('Time settings')}
                                                    options={timeSlotTimeTypesOptions}
                                                    error={errors[fNames.timeType] && errors[fNames.timeType].message}
                                                    control={control}
                                                />
                                                <FormFieldHidable hidden={false}>
                                                    <TimePickerFormFieldController
                                                        name={fNames.startTime}
                                                        defaultValue={ts.startTimeDescription}
                                                        label={`${Localization.getString('Start time')}`}
                                                        error={
                                                            errors[fNames.startTime]
                                                                ? errors[fNames.startTime].message
                                                                : ''
                                                        }
                                                        id={fNames.startTime}
                                                        control={control}
                                                    />
                                                    <TimePickerFormFieldController
                                                        name={fNames.endTime}
                                                        defaultValue={ts.endTimeDescription}
                                                        label={`${Localization.getString('End time')}`}
                                                        error={
                                                            errors[fNames.endTime] ? errors[fNames.endTime].message : ''
                                                        }
                                                        id={fNames.endTime}
                                                        control={control}
                                                    />
                                                </FormFieldHidable>
                                                <ToggleFieldController
                                                    name={fNames.isExternalId}
                                                    label={Localization.getString('Is External')}
                                                    id={fNames.externalId}
                                                    control={control}
                                                    onChange={(e: boolean) => {
                                                        if (!e) {
                                                            setValue(fNames.externalProviderId, undefined)
                                                            setValue(fNames.externalProviderCodeType, ExternalProviderCodeType.None)
                                                            setValue(fNames.externalId, "")
                                                            setValue(fNames.externalPerChildId, "")
                                                            setValue(fNames.bookingReferencePrefix, "")
                                                            setValue(fNames.externalProductId, "")
                                                            setValue(fNames.externalProductPerChildId, "")

                                                        }
                                                    }}
                                                />
                                                {getValues(fNames.isExternalId)
                                                    ? <>
                                                        <SelectFormFieldController
                                                            name={fNames.externalProviderId}
                                                            label={Localization.getString('External provider') + (getValues(fNames.externalProviderCodeType) ? ` (Code Type: ${ExternalProviderCodeTypesNames[getValues(fNames.externalProviderCodeType)]})` : "")}
                                                            options={externalProvidersOptions || []}
                                                            error={errors[fNames.externalProviderId] && errors[fNames.externalProviderId].message}
                                                            control={control}
                                                            onChange={(e: SelectOptionModel) => {
                                                                const externalProviderId = e.value as number
                                                                if (!externalOffersMap?.has(externalProviderId)) {
                                                                    dispatch(timeSlotsActions.requestExternalOffers(externalProviderId))
                                                                }
                                                                setValue(fNames.externalProviderDiffProductsAdultChild, (e as ExternalProviderOption).isDifferentProducts)
                                                                setValue(fNames.externalProviderCodeType, (e as ExternalProviderOption).codeType)
                                                                setValue(fNames.bookingReferencePrefix, (e as ExternalProviderOption).bookingReferencePrefix)
                                                                if (externalProviderId != getValues(fNames.externalProviderId)) {
                                                                    setValue(fNames.externalId, "")
                                                                    setValue(fNames.externalPerChildId, "")
                                                                    setValue(fNames.externalProductId, "")
                                                                    setValue(fNames.externalProductPerChildId, "")
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                    : null}
                                                {getValues(fNames.isExternalId) ? <ToggleFieldController
                                                    name={fNames.externalIsTicketPerPax}
                                                    label={Localization.getString('Is Ticket Per Pax')}
                                                    subLabel='If selected, then voucher will be generated for each pax with ticket number in QR code'
                                                    id={fNames.externalIsTicketPerPax}
                                                    control={control}
                                                /> : null}
                                                {getValues(fNames.isExternalId) ? <SelectFormFieldController
                                                    name={fNames.externalProductId}
                                                    label={Localization.getString('External product')}
                                                    options={externalOffersMap?.get(getValues(fNames.externalProviderId)) || []}
                                                    error={errors[fNames.externalProviderId] && errors[fNames.externalProviderId].message}
                                                    control={control}
                                                    onChange={(e: SelectOptionModel) => {
                                                        const externalProductId = e.value as string
                                                        const apiProviderId = getValues(fNames.externalProviderId)
                                                        if (!externalTimeSlotsMap?.has(apiProviderId) || !externalTimeSlotsMap.get(apiProviderId)?.has(externalProductId)) {
                                                            if (getValues(fNames.timeType) == TimeSlotTimeType.Fixed) {
                                                                dispatch(timeSlotsActions.requestExternalTimeSlots(getValues(fNames.externalProviderId), externalProductId))
                                                            }
                                                        }
                                                    }}
                                                /> : null}
                                                {(getValues(fNames.isExternalId) && getValues(fNames.externalProviderDiffProductsAdultChild))
                                                    ? <SelectFormFieldController
                                                        name={fNames.externalProductPerChildId}
                                                        label={Localization.getString('External product for child')}
                                                        options={externalOffersMap?.get(getValues(fNames.externalProviderId)) || []}
                                                        error={errors[fNames.externalProviderId] && errors[fNames.externalProviderId].message}
                                                        control={control}
                                                        onChange={(e: SelectOptionModel) => {
                                                            const externalProductPerChildId = e.value as string
                                                            const apiProviderId = getValues(fNames.externalProviderId)
                                                            if (!externalTimeSlotsMap?.has(apiProviderId) || !externalTimeSlotsMap.get(apiProviderId)?.has(externalProductPerChildId)) {
                                                                if (getValues(fNames.timeType) == TimeSlotTimeType.Fixed) {
                                                                    dispatch(timeSlotsActions.requestExternalTimeSlots(getValues(fNames.externalProviderId), externalProductPerChildId))
                                                                }
                                                            }
                                                        }}
                                                    /> : null}
                                                {(getValues(fNames.isExternalId) && getValues(fNames.externalProductId) && getValues(fNames.timeType) == TimeSlotTimeType.Fixed)
                                                    ? <>
                                                        <SelectFormFieldController
                                                            name={fNames.externalId}
                                                            label={Localization.getString('External time slot')}
                                                            options={externalTimeSlotsOptions}
                                                            error={(errors[fNames.externalProductId] && errors[fNames.externalProductId].message) || (externalTimeSlotsOptions.length == 0 ? "No time slots for selected product. Maybe it should be guest defines time?" : undefined)}
                                                            control={control}
                                                        />
                                                    </>
                                                    : null}

                                                {(getValues(fNames.isExternalId) && getValues(fNames.externalProductPerChildId) && getValues(fNames.timeType) == TimeSlotTimeType.Fixed)
                                                    ? <>
                                                        <SelectFormFieldController
                                                            name={fNames.externalPerChildId}
                                                            label={Localization.getString('External time slot for child')}
                                                            options={externalTimeSlotsOptionsForChild}
                                                            error={(errors[fNames.externalProductPerChildId] && errors[fNames.externalProductPerChildId].message) || (externalTimeSlotsOptionsForChild.length == 0 ? "No time slots for selected product. Maybe it should be guest defines time?" : undefined)}
                                                            control={control}
                                                        />
                                                    </>
                                                    : null}

                                                {(getValues(fNames.isExternalId))
                                                    ? <>
                                                        <TextFormField
                                                            label={Localization.getString('Booking prefix')}
                                                            subLabel={(selectedProdiverOption && selectedProdiverOption.bookingReferencePrefix != getValues(fNames.bookingReferencePrefix)) ? `Default  prefix:${selectedProdiverOption?.bookingReferencePrefix}` : undefined}
                                                            defaultValue={timeSlots[index].bookingReferencePrefix}
                                                            placeholder={(selectedProdiverOption && selectedProdiverOption.bookingReferencePrefix != getValues(fNames.bookingReferencePrefix)) ? `Default  prefix:${selectedProdiverOption?.bookingReferencePrefix}` : undefined}
                                                            name={fNames.bookingReferencePrefix}
                                                            register={register}
                                                        />
                                                        {/* <SelectFormFieldController
                                                            name={fNames.bookingReferencePrefix}
                                                            label={Localization.getString('Booking prefix')}
                                                            placeholder={(selectedProdiverOption && selectedProdiverOption.bookingReferencePrefix != getValues(fNames.bookingReferencePrefix)) ? `Default  prefix:${selectedProdiverOption?.bookingReferencePrefix}` : undefined}
                                                            options={externalTimeSlotsOptionsForChild}
                                                            control={control}
                                                        /> */}
                                                    </>
                                                    : null}

                                                {/* <FormFieldHidable hidden={ts.timeType != TimeSlotTimeType.Anytime || ts.isExternalId}> */}
                                                <FormFieldHidable hidden={false}>
                                                    {/* <NumberFormField
                                                        name={fNames.duration}
                                                        register={register}
                                                        defaultValue={ts.duration}
                                                        label={`${Localization.getString(
                                                            'Duration',
                                                        )}, ${Localization.getString('hours')}`}
                                                        error={
                                                            errors.timeSlots &&
                                                                errors.timeSlots.length > 0 &&
                                                                !ts.duration
                                                                ? Localization.getString('Duration is required')
                                                                : undefined
                                                        }
                                                        min={0}
                                                        options={{
                                                            validate: (value: number) =>
                                                                ts.timeType == TimeSlotTimeType.Anytime && !value
                                                                    ? Localization.getString('Duration is required')
                                                                    : undefined,
                                                        }}
                                                    /> */}
                                                    <NumberFormField
                                                        name={fNames.interval}
                                                        register={register}
                                                        defaultValue={ts.interval}
                                                        label={`${Localization.getString(
                                                            'Interval',
                                                        )}, ${Localization.getString('minutes')}`}
                                                        error={
                                                            errors.timeSlots &&
                                                                errors.timeSlots.length > 0 &&
                                                                !ts.interval
                                                                ? Localization.getString('Interval is required')
                                                                : undefined
                                                        }
                                                        options={{
                                                            validate: (value: number) =>
                                                                ts.timeType == TimeSlotTimeType.Anytime && !value && !ts.isExternalId
                                                                    ? Localization.getString('Interval is required')
                                                                    : undefined,
                                                        }}
                                                        min={0}
                                                    />
                                                </FormFieldHidable>
                                            </Col>
                                        </Row>,
                                        <Row key={`${ts.guid}.${index}.hr`}>
                                            <Col type="100">
                                                <hr />
                                            </Col>
                                        </Row>,
                                    ]
                                })
                            ) : (
                                <FieldFooter
                                    type="error"
                                    error={
                                        <>
                                            <i className="fa fa-warning" />
                                            {Localization.getString('Warning: Please add at least on time slot')}
                                        </>
                                    }
                                />
                            )}
                            {timeSlots && timeSlots.length > 0
                                ? <Tooltip text={Localization.getString('Add time slot')}>
                                    <Buttons.ButtonSmallGreen onClick={onAddTimeSlot}>
                                        <i className="fa fa-plus" />
                                    </Buttons.ButtonSmallGreen>
                                </Tooltip>
                                : undefined}
                        </Container>
                        <Buttons.StickySaveButton>{Localization.getString('Save')}</Buttons.StickySaveButton>
                    </form>
                </Grid>
            ) : (
                <LoadingSection />
            )}
        </ContainerWithPadding>
    )
}
export default TimeSlotsForm
